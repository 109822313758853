
import Notification from '@/components/helper/Notification.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import IconUser from '@/components/svg/IconUser.vue'
import SubscriptionCancelation from '@/mixin/SubscriptionCancelation'
import { BillingSource, BillingStatus, InvoiceStatus, PaymentIntentStatus, SubscriptionStatus } from '@/modules/subscription/types'
import { Permissions } from '@/security/permissions'
import { Tenant, TenantSetupJob, TenantSubscription } from '@/store/modules/app/types'
import { DynaconfConfig, Namespace, PricignListItem, PricingTier, UploadTenantLogoRequest } from '@/store/types'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation, State } from 'vuex-class'

import JigTextField from '@/components/input/JigTextField.vue'
import ImageUploader from '@/components/modals/ImageUploader.vue'
import { SubscriptionConst, TenantConst } from '@/constants'
import { PermissionActions } from '@/store/constants'
import { AppHelper } from '@/utils/app-helper'
import { DateFormatter } from '@/utils/date-formatter'
import { HSHelpers } from '@/utils/hs-helpers'
import { ValidationRules } from '@/utils/input-validation'
import { ColorKey, TenantHelpers } from '@/utils/tenant-helpers'

declare var consoleLog: any

@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'image-uploader': ImageUploader,
    'jig-text-field': JigTextField,
    notification: Notification,
    'icon-user': IconUser,
  },
})
export default class TenantVue extends Mixins(SubscriptionCancelation) {
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('tenantSetupJob', { namespace: Namespace.App })
  public tenantSetupJob!: TenantSetupJob
  @State('dynaconfConfig', { namespace: Namespace.App })
  public dynaconfConfig!: DynaconfConfig
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Mutation('updateMyTenant', { namespace: Namespace.App })
  public updateMyTenantState: any
  @Mutation('updateTenant', { namespace: Namespace.App })
  public updateTenant: any

  @Action('loadMyTenant', { namespace: Namespace.App })
  public loadMyTenant: any
  @Action('loadMyTenantLogo', { namespace: Namespace.App })
  public loadMyTenantLogo: any
  @Action('uploadTenantLogoImage', { namespace: Namespace.App })
  public uploadTenantLogoImage: any
  @Action('updateMyTenant', { namespace: Namespace.App })
  public updateMyTenant: any
  @Action('updateMyTenantsWithNewTenant', { namespace: Namespace.App })
  public updateMyTenantsWithNewTenant: any
  @Action('deleteTenantById', { namespace: Namespace.App })
  public deleteTenantById: any
  @Action('setMyTenant', { namespace: Namespace.App })
  public setMyTenant: any
  @Action('getTenantSetupJob', { namespace: Namespace.App })
  public getTenantSetupJob: any
  @Action('completeTenantSetupJob', { namespace: Namespace.App })
  public completeTenantSetupJob: any
  @Action('getSubscription', { namespace: Namespace.App })
  public getSubscription: any
  @Action('getDynaconfConfig', { namespace: Namespace.App })
  public getDynaconfConfig: any

  public $refs!: Vue['$refs'] & {
    logoImageUploader: ImageUploader
    deletePromptYesNo: PromptYesNo
    savePromptYesNo: PromptYesNo
    cancelSubscriptionPromptYesNo: PromptYesNo
  }

  private readonly saveChangesSuccessMsg = 'Team changes saved'
  private readonly defaultColor1Hex = TenantHelpers.TenantDefaultColor1Hex
  private readonly defaultColor2Hex = TenantHelpers.TenantDefaultColor2Hex

  private editedTenant!: Tenant
  private formIsValid: boolean = false
  private nameRules!: any
  private urlRules!: any
  private noneNegativeNumberRules!: any
  private snackbarVisible: boolean = false
  private snackbarMessage: string = this.saveChangesSuccessMsg
  private isSaving: boolean = false

  private isUserBillingContact: boolean = false
  private isAppleBillingSource: boolean = false
  private isStripeBillingSource: boolean = false
  private isNoneBillingSource: boolean = false
  private tokenBillingStatus!: string
  private openHSChatFailed: boolean = false

  private showCompleteTenantSetupButton: boolean = false
  private isLoadingCompleteTenantSetupJob: boolean = false
  private subscriptionStatusColor: string = ''
  private subscriptionInvoiceStatusColor: string = ''
  private subscriptionInvoicePaymentIntentStatusColor: string = ''
  private subscriptionShowContactUs: boolean = false
  private subscriptionStatusLabels = new Map([
    [SubscriptionStatus.Active, 'Active'],
    [SubscriptionStatus.Trialing, 'Trialing'],
    [SubscriptionStatus.IncompleteExpired, 'Requires Attention'],
    [SubscriptionStatus.Incomplete, 'Requires Attention'],
    [SubscriptionStatus.PastDue, 'Requires Attention'],
    [SubscriptionStatus.Unpaid, 'Requires Attention'],
    [SubscriptionStatus.Canceled, 'Canceled'],
    [BillingStatus.Paid, 'Paid'],
    [BillingStatus.Trial, 'Trialing'],
    [BillingStatus.BillingIssuesDetected, 'Requires Attention'],
    [BillingStatus.Expired, 'Expired'],
    [BillingStatus.UserUnsubscribed, 'User Unsubscribed'],
    [BillingStatus.NeverSubscribed, 'Not Found'],
    [BillingStatus.NewUnknownStatus, 'Internal Error'],
  ])
  private currentSubscription: TenantSubscription = SubscriptionConst.defaultSubscription
  private subscriptionTiersValue: SubscriptionConst.Subscription[] = SubscriptionConst.subscriptionTiers
  private subscriptionManagedByTypeValue: any[] = SubscriptionConst.subscriptionManagedByTypes
  private pricingSetsValue: SubscriptionConst.Subscription[] = SubscriptionConst.pricingSets // fallback default value
  private dynaconfPricingListNames: SubscriptionConst.Subscription[] = []
  private allowMovoJigs: boolean = false
  private allowJigStats: boolean = false
  private AVPFeatureGateEnabled: boolean = true

  constructor() {
    super()
    this.nameRules = ValidationRules.RequiredName
    this.urlRules = ValidationRules.OptionalUrl
    this.noneNegativeNumberRules = ValidationRules.RequiredNoneNegativeNumber

    this.currentSubscription.SubscriptionFeatures.avp.gatedFeatures = {
      [SubscriptionConst.Features.AVPFullAccessFeatureGate]: {
        name: 'ViewingGate_MAR_2024',
        enforce: false,
      },
    }
    this.editedTenant = { ...TenantConst.defaultTenant }
  }

  protected async mounted() {
    this.$refs.logoImageUploader.Init(ValidationRules.PngImageUpload)
    this.loadData()
  }

  @Watch('myTenantIndex')
  private onMyTenantIndexPropertyChanged(value: number, oldValue: number) {
    this.loadData()
    this.$refs.logoImageUploader.ClearImageData()
  }

  private get dialogActiveLabel(): string {
    return this.editedTenant.Active ? 'Active' : 'Inactive'
  }

  private get allowMovoJigsLabel(): string {
    return this.allowMovoJigs ? 'Movo Jigs enabled' : 'Movo Jigs disabled'
  }

  private get allowJigStatsLabel(): string {
    return this.allowJigStats ? 'Jig Stats enabled' : 'Jig Stats disabled'
  }

  private get AVPFeatureGateLabel(): string {
    return this.AVPFeatureGateEnabled ? 'Full AVP access disabled' : 'Full AVP access enabled'
  }

  // classic pricing set: set_2020_01
  get isClassicPricingSet() {
    return this.editedTenant.Subscription != null && !this.dynaconfPricingListNames.includes(this.editedTenant.Subscription.PricingSet)
  }

  get isPricingSetWithSeatsOrMaxJigs(): boolean {
    const targetPricingSet = this.dynaconfConfig.pricing.sets[this.editedTenant.Subscription.PricingSet]

    return (
      targetPricingSet &&
      this.editedTenant.Subscription.TierType != '' &&
      (targetPricingSet.tiers[this.editedTenant.Subscription.TierType].maxJigs != null ||
        targetPricingSet.tiers[this.editedTenant.Subscription.TierType].seatsIncluded != null)
    )
  }

  get isMaxJigsSet(): boolean {
    const targetPricingSet = this.dynaconfConfig.pricing.sets[this.editedTenant.Subscription.PricingSet]
    // When MaxJigs === 0 it means unlimited
    return (
      this.isPricingSetWithSeatsOrMaxJigs &&
      targetPricingSet.tiers[this.editedTenant.Subscription.TierType].maxJigs != null &&
      this.editedTenant.Subscription.MaxJigs !== 0
    )
  }

  get subscriptionStatusDisplay() {
    if (this.isAppleBillingSource) {
      return this.subscriptionStatusLabels.get(this.tokenBillingStatus as string)
    } else {
      return this.subscriptionStatusLabels.get(this.subscription.status as string)
    }
  }

  get isSubscriptionNotActiveButHasInvoice() {
    return this.subscription.status !== SubscriptionStatus.Active && this.subscription.latest_invoice
  }

  get isSubscriptionPaymentIntentRequiresAction() {
    if (this.subscription.latest_invoice.payment_intent) {
      return this.subscription.latest_invoice.payment_intent.status === PaymentIntentStatus.RequiresAction
    } else {
      return false
    }
  }

  get isSubscriptionTrialling() {
    return this.subscription.status === SubscriptionStatus.Trialing
  }

  get trialEndDisplay() {
    // trial_end is a unix timestamp, in seconds.
    return DateFormatter.formatSubscriptionDate(this.subscription.trial_end) // Eg "Tomorrow at 10:12 AM" or "Sunday at 2:30 AM" or if longer than 1 week away "7/10/2020"
  }

  get deleteButtonDisabled(): boolean {
    let disabled = this.customBrandingDisabled
    if (!disabled) {
      // Don’t allow deletion of tenants that have existing users
      disabled = this.editedTenant.UserCount === undefined || this.editedTenant.UserCount > 0
    }
    return disabled
  }

  // User can update branding (team settings) when:
  // 1. user is a Super User (JigStaff only)
  // 2. AllowCustomBranding is true and user has team manage permission (Team Manager / Team Admin)
  get customBrandingDisabled(): boolean {
    if (this.editedTenant.ID === TenantHelpers.InvalidTenantID) {
      return true
    }

    if (!this.editedTenant.Subscription.AllowCustomBranding && !this.isSuperUser) {
      return true
    }

    // If we are not a super user and we don't have manage tenant permissions, disable saving.
    const tenantTokens = this.$auth0.tenants
    const tenantToken = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, this.editedTenant.ID)
    if (
      !this.isSuperUser &&
      (tenantToken === undefined || !Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermTenant, PermissionActions.Manage))
    ) {
      return true
    }

    return this.$refs.logoImageUploader.IsShowingDialog || this.isSaving
  }

  // Disable the team field and active/inactive switcher when it's JigSpaceStaff tenant.
  get isTenantJigSpaceStaff(): boolean {
    return TenantHelpers.IsTenantJigSpaceStaff(this.editedTenant)
  }

  get tenantNotes(): string {
    return this.editedTenant.Subscription.AllowCustomBranding
      ? 'People viewing your Jigs will go to this link if they click on your logo.'
      : 'Upgrade your plan to whitelabel the JigSpace viewer.'
  }

  private async loadData() {
    this.refreshEditedTenant()
    if (this.editedTenant.Logourl && this.editedTenant.Logourl.length > 0) {
      await this.loadMyTenantLogo(this.editedTenant)
      this.refreshEditedTenant()
    }

    if (Object.keys(this.dynaconfConfig.pricing.sets).length === 0) {
      await this.getDynaconfConfig()
    }

    if (Object.keys(this.dynaconfConfig.pricing.sets).length > 0) {
      this.dynaconfPricingListNames = Object.keys(this.dynaconfConfig.pricing.sets) as SubscriptionConst.Subscription[]
      this.pricingSetsValue = [SubscriptionConst.Subscription.pricingSet_2020_01, ...this.dynaconfPricingListNames]
    }

    this.loadSubscription()
    this.currentSubscription = {
      ...this.editedTenant.Subscription,
    }
  }

  private openLogoUploadDialog(tenant: any) {
    this.editedTenant = Object.assign({}, tenant as Tenant)
    this.editedTenant.LogoUpdated = false

    this.$refs.logoImageUploader.Show(
      null,
      (file: File): Promise<boolean> =>
        this.uploadTenantLogoImage({
          TenantId: this.editedTenant.ID,
          Image: file,
        } as UploadTenantLogoRequest),
      (): any => this.onPostLogoUpload()
    )
  }

  private showCancelSubscriptionPrompt() {
    this.$refs.cancelSubscriptionPromptYesNo.ShowPrompt()
  }

  private showDeleteTenantPrompt(t: Tenant) {
    this.$refs.deletePromptYesNo.ShowPrompt()
  }

  private async deleteTenant(t: Tenant) {
    await this.deleteTenantById(t.ID)
    if (this.apiError.length === 0) {
      this.setMyTenant({
        TenantId: TenantHelpers.GetJigSpaceStaffTenantID(),
      })
      this.$router.push('/tenants')
    }
  }

  private showSaveTenantPrompt(t: Tenant) {
    this.$refs.savePromptYesNo.ShowPrompt()
  }

  private async onSavePromptSuccess(t: Tenant) {
    this.saveChanges(t)
  }

  private async saveChanges(t: Tenant) {
    if (this.customBrandingDisabled) {
      return
    }

    this.isSaving = true
    this.snackbarVisible = false

    const tenantCopy = Object.assign({}, t)

    tenantCopy.ColorHex1 = this.removeAlphaFromHexColor(tenantCopy.ColorHex1)
    tenantCopy.ColorHex2 = this.removeAlphaFromHexColor(tenantCopy.ColorHex2)

    const tenantPricingSet: PricignListItem = this.dynaconfConfig.pricing.sets[t.Subscription.PricingSet]

    if (tenantPricingSet) {
      const tenantPricingSetTier: PricingTier = tenantPricingSet.tiers[t.Subscription.TierType]

      if (tenantPricingSetTier.seatsIncluded != null) {
        t.Subscription.SeatsIncludedInTier = tenantPricingSetTier.seatsIncluded
      } else {
        // API will handle the correct SeatsIncludedInTier number.
        t.Subscription.SeatsIncludedInTier = SubscriptionConst.Subscription.defaultSeatsIncludedInTier
      }
      if (tenantPricingSetTier.maxJigs != null) {
        t.Subscription.MaxJigs = tenantPricingSetTier.maxJigs
      } else {
        // API will handle the correct MaxJigs.
        t.Subscription.MaxJigs = SubscriptionConst.Subscription.defaultMaxJigsInTier
      }
      if (tenantPricingSetTier.maxJigsCountPer != null) {
        t.Subscription.MaxJigsCountPer = tenantPricingSetTier.maxJigsCountPer
      } else {
        // API will handle the correct MaxJigsCountPer.
        t.Subscription.MaxJigsCountPer = SubscriptionConst.Subscription.defaultMaxJigsPerInTier
      }
    }

    if (t.Subscription.AdditionalSeats && typeof t.Subscription.AdditionalSeats !== 'number') {
      t.Subscription.AdditionalSeats = parseInt(t.Subscription.AdditionalSeats)
    }

    // Append url protocol if there isn't one.
    if (tenantCopy.JigFinishedCustomLink && !tenantCopy.JigFinishedCustomLink.includes('://')) {
      tenantCopy.JigFinishedCustomLink = `http://${tenantCopy.JigFinishedCustomLink}`
    }

    // Update features
    // For AVP full access, we follow following logic:
    // `Full AVP access enabled` === `AVPFeatureGate not shown` === enabled: true, enforce: false`
    // `Full AVP access disabled` === `AVPFeatureGate shown` === enabled: false, enforce: true`
    tenantCopy.Subscription.SubscriptionFeatures.avp.enabled = !this.AVPFeatureGateEnabled
    if (tenantCopy.Subscription.SubscriptionFeatures.avp.gatedFeatures) {
      tenantCopy.Subscription.SubscriptionFeatures.avp.gatedFeatures[SubscriptionConst.Features.AVPFullAccessFeatureGate].enforce =
        this.AVPFeatureGateEnabled
    }
    tenantCopy.Subscription.SubscriptionFeatures.movo.enabled = this.allowMovoJigs
    tenantCopy.Subscription.SubscriptionFeatures.stats.jigStats.enabled = this.allowJigStats

    // Update legacy values. When API is fixed we can remove them.
    tenantCopy.Subscription.AllowMovoJigs = this.allowMovoJigs
    tenantCopy.Subscription.AllowJigStats = this.allowJigStats

    await this.updateMyTenant(tenantCopy)
    if (this.apiError === undefined || this.apiError.length === 0) {
      this.snackbarVisible = true
      await this.loadMyTenant({ TenantId: this.editedTenant.ID })
      if (this.isSuperUser) {
        this.updateMyTenantsWithNewTenant(this.editedTenant)
      }
    }

    this.refreshEditedTenant()
    this.isSaving = false
  }

  private removeAlphaFromHexColor(hex: string): string {
    if (hex.length > 7) {
      hex = hex.substr(1, 6)
    }
    return hex
  }

  private async onPostLogoUpload() {
    this.$refs.logoImageUploader.Hide()

    // We've just finished uploading a new logo url but so far our screen doesn't know about it's result.
    // Pull down the latest tenant data and update our local copy so we get the new Logourl.
    await this.loadMyTenant({ TenantId: this.editedTenant.ID })
    this.editedTenant = AppHelper.deepCopyNestedJsonSafeObject(this.myTenant)
    this.editedTenant.LogoUpdated = true
    this.updateMyTenantState(this.editedTenant)
    if (this.isSuperUser) {
      this.updateTenant(this.editedTenant)
      this.updateMyTenantsWithNewTenant(this.editedTenant)
    }
    this.refreshEditedTenant()

    // Does this tenant have an uploaded logo to download?
    if (this.editedTenant.Logourl !== '') {
      // Get the tenant logo and update our local tenant copy again
      await this.loadMyTenantLogo(this.editedTenant)
      this.refreshEditedTenant()
    }

    // To update the logo in other places (like the drawer), we have to trigger the watch on myTenant which is pretty hacky but still an improvement.
    var oldValue = this.$store.state.app.myTenant
    this.$store.state.app.myTenant = undefined
    this.$store.state.app.myTenant = oldValue
  }

  private refreshEditedTenant() {
    if (this.myTenantIndex === -1) {
      return
    }
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)

    // Use JSON.stringfy and JSON.parse for a deep copy of the value not reference.
    // So that any unsaved changes won't update the original `tenant` item of `state.myTenants` array and `state.myTenant` object.
    // When using Object.assign, top-level properties are assigned by value, however nested objects/references are still shared between original `tenant` and `editedTenant`
    // Note that as our tenant object is relatively simple, we can use JSON parse/stringify. If tenant object contains non-JSON-safe value (e.g., functions, undefined, NaN).
    this.editedTenant = AppHelper.deepCopyNestedJsonSafeObject(tenant)

    this.allowMovoJigs = this.editedTenant.Subscription.SubscriptionFeatures.movo.enabled
    this.allowJigStats = this.editedTenant.Subscription.SubscriptionFeatures.stats.jigStats.enabled
    // By default for new tenants or existing tenants that
    // haven't updated this flag before
    // `enabled` and `enforce` values will both be `true`.
    // We check `enforce` value for actual Featuregated status.
    this.AVPFeatureGateEnabled =
      this.editedTenant.Subscription.SubscriptionFeatures.avp != null &&
      this.editedTenant.Subscription.SubscriptionFeatures.avp.gatedFeatures != null &&
      this.editedTenant.Subscription.SubscriptionFeatures.avp.gatedFeatures.ViewingGate_MAR_2024.enforce

    // Correct any invalid colors set as "" or "      "
    if (!this.editedTenant.ColorHex1 || !this.editedTenant.ColorHex1.trim()) {
      this.editedTenant.ColorHex1 = TenantHelpers.TenantDefaultColor1Hex
    }
    if (!this.editedTenant.ColorHex2 || !this.editedTenant.ColorHex2.trim()) {
      this.editedTenant.ColorHex2 = TenantHelpers.TenantDefaultColor2Hex
    }
  }

  private async loadSubscription() {
    this.IsLoadingSubscription = true
    this.isUserBillingContact = false
    this.isAppleBillingSource = false
    this.isStripeBillingSource = false
    this.isNoneBillingSource = false

    this.showCompleteTenantSetupButton = false

    var tenantTokens = this.$auth0.tenants
    var thisTenantToken = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, this.editedTenant.ID)
    if (thisTenantToken === undefined) {
      this.IsLoadingSubscription = false
      return
    }

    this.isUserBillingContact = Permissions.TokenHasPermissionAction(
      thisTenantToken,
      Permissions.PermTenant,
      PermissionActions.BillingContact
    )
    this.isAppleBillingSource = thisTenantToken.billing_source === BillingSource.Apple
    this.isStripeBillingSource = thisTenantToken.billing_source === BillingSource.Stripe
    this.isNoneBillingSource = thisTenantToken.billing_source === BillingSource.None

    if (this.isUserBillingContact) {
      if (this.isAppleBillingSource) {
        // If the tenant is a billing source apple, we direct them to apple subscription page to manage it.
        // https://apps.apple/account/subscriptions
        this.tokenBillingStatus = thisTenantToken.billing_status
        var logMsg = `Subscription Status = ${this.tokenBillingStatus}`
        if (this.tokenBillingStatus === BillingStatus.Paid || this.tokenBillingStatus === BillingStatus.Trial) {
          this.subscriptionStatusColor = 'green'
        } else {
          this.subscriptionStatusColor = 'red'
        }
      } else if (this.isStripeBillingSource) {
        if (
          await this.getSubscriptionWithInvoice({
            customerEmail: this.$auth0.user.email,
            tenantId: this.editedTenant.ID,
          })
        ) {
          var logMsg = `Subscription Status = ${this.subscription.status} `
          if (this.subscription.status === SubscriptionStatus.Active || this.subscription.status === SubscriptionStatus.Trialing) {
            this.subscriptionStatusColor = 'green'
          } else {
            this.subscriptionStatusColor = 'red'
            this.subscriptionShowContactUs =
              this.subscription.status === SubscriptionStatus.IncompleteExpired ||
              this.subscription.status === SubscriptionStatus.Canceled ||
              this.subscription.status === SubscriptionStatus.Unpaid
          }
          if (this.subscription.latest_invoice) {
            logMsg += `Latest Invoice Status = ${this.subscription.latest_invoice.status} `
            if (this.subscription.latest_invoice.status === InvoiceStatus.Paid) {
              this.subscriptionInvoiceStatusColor = 'green'
            } else {
              this.subscriptionInvoiceStatusColor = 'red'
            }
          }
          if (this.subscription.latest_invoice && this.subscription.latest_invoice.payment_intent) {
            logMsg += `Payment Intent Status = ${this.subscription.latest_invoice.payment_intent.status} `
            if (this.subscription.latest_invoice.payment_intent.status === PaymentIntentStatus.Succeeded) {
              this.subscriptionInvoicePaymentIntentStatusColor = 'green'
            } else {
              this.subscriptionInvoicePaymentIntentStatusColor = 'red'
            }
          }
          consoleLog(logMsg)
        }
        if (
          this.subscription.status === SubscriptionStatus.Active &&
          (await this.getTenantSetupJob({ userEmail: this.$auth0.user.email })) &&
          this.tenantSetupJob.status !== '' &&
          this.tenantSetupJob.createdTenantId === this.editedTenant.ID // Just in case this user is currently setting up a different tenant, check the id's are the same.
        ) {
          // The subscription is active, but there's still a tenant-setup-job, so complete it.
          this.isLoadingCompleteTenantSetupJob = true
          if (await this.completeTenantSetupJob()) {
            await this.loadSubscription()
          }
          this.isLoadingCompleteTenantSetupJob = false
        }
      }
    } else {
      consoleLog(`No subscription available because user is not a billing contact. Billing Source is ${thisTenantToken.billing_source}`)
      // Until we have the billing contact set as a variable in the tenant, so we know their email, we can't get the subscription status from stripe
      // Only the billing_contact themselves can.
      // if (await this.getSubscription({ customerEmail: this.editedTenant.billingContactEmail, tenantId: this.editedTenant.ID })) {
      //   consoleLog(JSON.stringify(this.subscription))
      //   debugger
      // }
    }
    this.IsLoadingSubscription = false
  }

  private async completeTenantSetupJobClick() {
    this.isLoadingCompleteTenantSetupJob = true
    if (await this.completeTenantSetupJob()) {
      await this.loadSubscription()
    }
    this.isLoadingCompleteTenantSetupJob = false
  }

  private closeSnackbar() {
    this.snackbarVisible = false
  }

  private resetColorToDefault(tenant: Tenant, key: ColorKey) {
    if (key === 'Color1') {
      tenant.ColorHex1 = TenantHelpers.TenantDefaultColor1
    } else if (key === 'Color2') {
      tenant.ColorHex2 = TenantHelpers.TenantDefaultColor2
    }
  }

  private openHSChat() {
    this.openHSChatFailed = !HSHelpers.OpenHSChat()
  }

  private updateDataFromTier() {
    // If subscription tier has changed, show `-` as Seats Included,
    // as client side has no details of seats included number,
    // it's handled by API. If subscription tier changed back to initial
    // value, show original SeatsIncluded number.
    if (this.editedTenant.Subscription.TierType === this.currentSubscription.TierType) {
      this.editedTenant.Subscription.SeatsIncludedInTier = this.currentSubscription.SeatsIncludedInTier
      this.editedTenant.Subscription.MaxJigs = this.currentSubscription.MaxJigs
      this.editedTenant.Subscription.MaxJigsCountPer = this.currentSubscription.MaxJigsCountPer
    } else {
      const tenantPricingSet: PricignListItem = this.dynaconfConfig.pricing.sets[this.editedTenant.Subscription.PricingSet]
      const tenantPricingSetTier: PricingTier = tenantPricingSet.tiers[this.editedTenant.Subscription.TierType]

      this.editedTenant.Subscription.SeatsIncludedInTier =
        tenantPricingSetTier.seatsIncluded || SubscriptionConst.Subscription.defaultSeatsIncludedInTier
      this.editedTenant.Subscription.MaxJigs = tenantPricingSetTier.maxJigs || SubscriptionConst.Subscription.defaultMaxJigsInTier
      this.editedTenant.Subscription.MaxJigsCountPer =
        tenantPricingSetTier.maxJigsCountPer || SubscriptionConst.Subscription.defaultMaxJigsPerInTier
    }
  }
}
